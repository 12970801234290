<template>
  <div class="container-fluid bg-dark">
    <div class="row py-2 px-lg-5">
      <div class="col-lg-6 text-center text-lg-left mb-2 mb-lg-0">

      </div>
      <div class="col-lg-6 text-center text-lg-right">
        <div class="d-inline-flex align-items-center">

          <a class="text-white px-2" href="https://twitter.com/pumpkin_catz">
            <font-awesome-icon :icon="['fab', 'twitter']" />
          </a>
          <a class="text-white px-2" href="https://discord.gg/xCzRmnUPc5">
            <font-awesome-icon :icon="['fab', 'discord']" />
          </a>
          <a class="text-white pl-2" href="https://www.youtube.com/channel/UCVBe1tFm6LP8kf_EREjFi-Q">
            <font-awesome-icon :icon="['fab', 'youtube']" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid p-0 mobile-nav">
    <nav class="navbar navbar-expand-lg bg-white navbar-light py-3 py-lg-0 px-lg-5">
      <a href="/" class="navbar-brand ml-lg-3">
        <h1 class="m-0 header"><img src="@/img/PumpkinCatzIcon2.png" class="logo-header">PumpkinCatz</h1>
      </a>
      <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse" @click="isOpen = !isOpen">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-between px-lg-3" id="navbarCollapse" v-if="isOpen">
        <div class="navbar-nav mx-auto py-0">
          <router-link to="/" class="nav-item nav-link">Home</router-link>
          <router-link to="/about" class="nav-item nav-link">About</router-link>
          <router-link to="/faq" class="nav-item nav-link">FAQ</router-link>
          <router-link to="/donations" class="nav-item nav-link">Donations</router-link>
          <router-link to="/giveaways" class="nav-item nav-link">Giveaways</router-link>
          <dropdown title="The Catz" :items="catz" />
        </div>
        <a href="https://easycnft.art/en/flash/1557" class="btn btn-primary py-2 px-4 d-none d-lg-block">Mint Meow!</a>
      </div>
    </nav>
  </div>
  <div class="container-fluid p-0 normal-nav">
    <nav class="navbar navbar-expand-lg bg-white navbar-light py-3 py-lg-0 px-lg-5">
      <a href="/" class="navbar-brand ml-lg-3">
        <h1 class="m-0 header"><img src="@/img/PumpkinCatzIcon2.png" class="logo-header">PumpkinCatz</h1>
      </a>
      <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse" >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-between px-lg-3">
        <div class="navbar-nav mx-auto py-0">
          <router-link to="/" class="nav-item nav-link">Home</router-link>
          <router-link to="/about" class="nav-item nav-link">About</router-link>
          <router-link to="/faq" class="nav-item nav-link">FAQ</router-link>
          <router-link to="/donations" class="nav-item nav-link">Donations</router-link>
          <router-link to="/giveaways" class="nav-item nav-link">Giveaways</router-link>
          <dropdown title="The Catz" :items="catz" />
        </div>
        <a href="https://easycnft.art/en/flash/1557" class="btn btn-primary py-2 px-4 d-none d-lg-block">Mint Meow!</a>
      </div>
    </nav>
  </div>
</template>

<script>
import Dropdown from './dropdown';
export default {
  name: 'thenav',
  components: {
    Dropdown
  },
  data () {
    return {
      isOpen: false,
      catz: [
        {
          title: 'Search',
          link: '/search'
        },
        {
          title: 'Rarity Table',
          link:'/rarity'
        },
      ]
    }
  },
  watch: {
    '$route' () {
      this.isOpen = false
    }
  }
}
</script>

<style scoped>

</style>
