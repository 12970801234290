<template>
<theheader></theheader>
  <main>
    <router-view :key="$route.path"></router-view>
  </main>
<thefooter></thefooter>
</template>

<script>
import theheader from './header'
import thefooter from './footer'

export default {
  name: 'container',
  components: {
    theheader,
    thefooter
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
