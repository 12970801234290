<template>
  <thenav></thenav>
</template>

<script>
import thenav from './nav'

export default {
  name: 'theheader',
  components: {
    thenav
  }
}
</script>

<style scoped>

</style>
