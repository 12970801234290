<template>
  <div class="container-fluid position-relative overlay-top bg-dark text-white-50 py-5" style="margin-top: 90px;">
    <div class="container mt-5 pt-5">
      <div class="row">
        <div class="col-md-6 mb-5">
          <a href="/" class="navbar-brand">
            <h1 class="mt-n2 text-white header"><img src="@/img/PumpkinCatzIcon2.png" class="logo-header">PumpkinCatz</h1>
          </a>
          <p class="m-0">Missed out on our initial drop? Want to buy, sell, or trade? We’re live on <a href="https://cnft.io/" class="footer-link" target="_blank"> CNFT.io</a>, <a href="https://tokhun.io/" class="footer-link" target="_blank">tokhun.io</a>. Everyone can build up a digital cat collection and share it with others. </p>
        </div>
        <div class="col-md-6 mb-5" style="text-align: right; float: right;">

          <h3 class="text-white mb-4" >Get In Touch</h3>
          <p><i class="fa fa-map-marker-alt mr-2"></i>Pumpkinland</p>
          <p><i class="fa fa-envelope mr-2"></i>pumpkincatz{@}protonmail.com</p>
          <div class="d-flex justify-content-start mt-4" style="float: right;">
            <a class="text-white mx-4" href="https://twitter.com/pumpkin_catz" style="text-align: right;"><i class="fab fa-2x fa-twitter"></i></a>
            <a class="text-white mx-4" href="https://discord.gg/xCzRmnUPc5" style="text-align: right;"><i class="fab fa-2x fa-discord"></i></a>
            <a class="text-white mx-4" href="https://www.youtube.com/channel/UCVBe1tFm6LP8kf_EREjFi-Q" style="text-align: right;"><i class="fab fa-2x fa-youtube"></i></a>
          </div></div>
      </div>
      <div class="container-fluid bg-dark text-white-50 border-top py-4" style="border-color: rgba(256, 256, 256, .1) !important;">
        <div class="container">
          <div class="row">
            <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
              <p class="m-0">Copyright &copy; <a class="text-white" href="#">PumpkinCatz</a>. All Rights Reserved.
              </p>
            </div>
            <div class="col-md-6 text-center text-md-right">
              <p class="m-0">Designed by <a class="text-white" href="https://htmlcodex.com">HTML Codex</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'thefooter'
}
</script>

<style scoped>

</style>
